import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types';
import OrderUtilities from '../common/OrderUtilities';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DatePicker } from "@material-ui/pickers"
import _ from 'lodash'
import NewOrderTable from "./NewOrderTable"
// import Box from '@material-ui/core/box'

NewOrder.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  customerRefs: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  pricing: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  rowColorMap: PropTypes.object.isRequired
}

const calcDefaultDeliveryDate = () => {
  const today = new Date()
  // start 2 days from current date
  let result = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2)
  // find 2nd business day from today
  // if date is Saturday, make it Monday
  if (result.getDay() === 6) {
    result = new Date(result.getFullYear(), result.getMonth(), result.getDate() + 2)
  }
  // if date is Sunday, make it Tuesday
  if (result.getDay() === 0) {
    result = new Date(result.getFullYear(), result.getMonth(), result.getDate() + 2)
  }
  return result
}

export default function NewOrder(props) {

  const firebase = props.firebase
  const db = props.db
  const onClose = props.onClose

  const [show] = useState(props.open)

  const lineTableRef = useRef()

  const defaultAnticipatedDeliveryDate = calcDefaultDeliveryDate()

  const [canAddOrder, setCanAddOrder] = useState(false)
  const [customerRef, setCustomerRef] = useState(null)
  const [city, setCity] = useState(null)
  const [notes, setNotes] = useState(null)
  const [contact, setContact] = useState(null)
  const [contactEmail, setContactEmail] = useState(null)
  const [subdivision, setSubdivision] = useState(null)
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState(null)
  const [purchaseOrder, setPurchaseOrder] = useState(null)
  const [lineItems, setLineItems] = useState([])

  const areaMap = _.keyBy(props.cities, (o) => { return o.name.trim() })
  const citiesMap = _.keyBy(props.cities, (o) => { return o.name.trim() })
  const subdivisionsMap = _.keyBy(props.subdivisions, (o) => { return o.trim() })
  const materialMap = _.keyBy(props.materials, (o) => { return o.name.trim() })
  const purposeMap = _.keyBy(props.purposes, (o) => { return o.name.trim() })

  const createOrderUtilities = () => {
    return new OrderUtilities(
      firebase, 
      db,
      areaMap,
      props.customerRefs,
      citiesMap,
      subdivisionsMap,
      materialMap,
      purposeMap,
      props.pricing)
  }

  const close = () => {
    onClose()
  }

  const lineItemsChanged = (newLineItems) => {
    setLineItems(newLineItems)
    if (newLineItems.length > 0) {
      setCanAddOrder(true)
    }
  }

  const validateLineItems = () => {
    for (let i = 0; i < lineItems.length; i++) {
      if (!lineItems[i].deliveryAddress) {
        alert("Delivery address is required")
        return false
      }
      if (!lineItems[i].material) {
        alert("Material is required")
        return false
      }
      if (!lineItems[i].loads) {
        alert("Number of loads is required")
        return false
      }
      if (!lineItems[i].price) {
        alert("Price is required")
        return false
      }  
    }
    return true
  }

  const onSubmitOrder = async () => {
    if (lineTableRef.current.state.lastEditingRow ||
        lineTableRef.current.state.showAddRow) {
      alert("Currently Editing Lines")
      return
    }
    if (!customerRef) {
      alert("Customer is required")
      return
    }
    if (!city) {
      alert("City is required")
      return
    }
    if (!validateLineItems()) {
      return
    }
    setCanAddOrder(false);
    const orderUtil = createOrderUtilities()
    const newOrders = []
    for (let i = 0; i < lineItems.length; i++) {
      const newOrder = orderUtil.getEmptyOrder()
      newOrder.customer = customerRef.data().name
      newOrder.billToAddress = lineItems[i].billToAddress ? lineItems[i].billToAddress :lineItems[i].deliveryAddress
      newOrder.deliveryAddress = lineItems[i].deliveryAddress
      newOrder.contact = contact ? contact : ''
      newOrder.contactEmail = contactEmail? contactEmail : ''
      newOrder.priority = lineItems[i].priority ? lineItems[i].priority : false
      newOrder.subdivision = subdivision ? subdivision : ''
      newOrder.city = city.name
      newOrder.material = lineItems[i].material
      newOrder.purpose = lineItems[i].purpose ? lineItems[i].purpose : ''
      newOrder.loads = lineItems[i].loads
      newOrder.price = lineItems[i].price
      newOrder.notes = notes ? notes : ''
      newOrder.po = purchaseOrder
      newOrder.requestedDeliveryDate = requestedDeliveryDate ? requestedDeliveryDate : null
      newOrder.anticipatedDeliveryDate = defaultAnticipatedDeliveryDate
      newOrders.push(newOrder)
    }
    await orderUtil.addMultiLineOrder(customerRef.data().name, purchaseOrder, newOrders, props.isFuture)
      .then(() => {
        close()
      })
      .catch( error => {
        setCanAddOrder(true)
        if (error) {
          alert(error.message)
        }
      })
  }

  const onCancel = () => {
    close()
  }

  const handleCustomerChange = (event, value) => {
    if (customerRef !== value) {
      setCustomerRef(value)
      if (!value) return
      setPurchaseOrder(value.data().poPrefix ? value.data().poPrefix : '')
      setNotes(value.data().defaultNotes)
      setContact(value.data().defaultContact)
      setContactEmail(value.data().defaultEmailPhone)
    }
  }

  const handleCityChange = (event, value) => {
    if (city !== value) {
      setCity(value)
    }
  }

  const handleNotesChange = (event) => {
    setNotes(event.target.value)
  }

  const handleContactChange = (event) => {
    setContact(event.target.value)
  }

  const handleContactEmailChange = (event) => {
    setContactEmail(event.target.value)
  }

  const handleSubdivisionChange = (event, value) => {
    if (subdivision !== value) {
      setSubdivision(value)
    }
  }

  const handleRequestedDeliveryDateChange = (value) => {
    setRequestedDeliveryDate(value)
  }

  const handlePurchaseOrderChange = (event) => {
    setPurchaseOrder(event.target.value)
  }

  return (
    <Dialog open={show} onClose={close} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle id="simple-dialog-title">{props.isFuture ? 'Create future order' : 'Create new order'}</DialogTitle>
      <DialogContent>
        <div style={{ width: '100%' }}>
          <Box width={1/2} component="div" display="inline" >
            <Autocomplete
              id="customers"
              size="small"
              fullWidth={true}
              required={true}
              options={props.customerRefs.sort((customerRefA, customerRefB) => {
                return customerRefA.data().name < customerRefB.data().name ? -1 : 1
              })}
              autoSelect={true}
              autoComplete={true}
              autoHighlight={true}
              openOnFocus={true}
              selectOnFocus={true}      
              disabled={lineItems.length > 0}
              getOptionLabel={(option) => option.data().name}
              onChange={handleCustomerChange}
              getOptionSelected={(option, value) => option.data().name === value.data().name }
              renderInput={(params) => <TextField {...params} label="Customer Name" InputLabelProps={{ shrink: true }} />}
            />  
          </Box>  
          <Box width={1/2} component="div" display="inline" >
            <TextField
              id="purchaseOrder"
              size="small"
              label="Purchase Order"
              InputLabelProps={{ shrink: true }}
              onChange={handlePurchaseOrderChange}
              fullWidth={true}
              value={purchaseOrder}
            />
          </Box>
          <Box width={1/2} component="div" display="inline" >
            <TextField
              id="contact"
              size="small"
              label="Contact"
              InputLabelProps={{ shrink: true }}
              onChange={handleContactChange}
              fullWidth={true}
              value={contact}
            />
          </Box>
          <Box width={1/2} component="div" display="inline" >
            <TextField
              id="contactEmail"
              size="small"
              label="Contact Email or Phone"
              InputLabelProps={{ shrink: true }}
              onChange={handleContactEmailChange}
              fullWidth={true}
              value={contactEmail}
            />
          </Box>
          <Box width={1/2} component="div" display="inline" >
            <Autocomplete
              id="subdivision"
              size="small"
              fullWidth={true}
              required={true}
              options={props.subdivisions.sort((a, b) => {
                return a < b ? -1 : 1
              })}
              autoSelect={true}
              autoComplete={true}
              autoHighlight={true}
              openOnFocus={true}
              selectOnFocus={true}      
              disabled={lineItems.length > 0}
              onChange={handleSubdivisionChange}
              renderInput={(params) => <TextField {...params} label="Subdivision" InputLabelProps={{ shrink: true }} />}
            />  
          </Box>
          <Box width={1/2} component="div" display="inline" >
            <Autocomplete
              id="city"
              size="small"
              fullWidth={true}
              required={true}
              options={props.cities.sort((cityA, cityB) => {
                return cityA.name < cityB.name ? -1 : 1
              })}
              autoSelect={true}
              autoComplete={true}
              autoHighlight={true}
              openOnFocus={true}
              selectOnFocus={true}      
              disabled={lineItems.length > 0}
              getOptionLabel={(option) => option.name}
              onChange={handleCityChange}
              getOptionSelected={(option, value) => option.name === value.name }
              renderInput={(params) => <TextField {...params} label="City" InputLabelProps={{ shrink: true }} />}
            />  
          </Box>
          <Box width={1/2} component="div" display="inline" >
            <TextField
              id="notes"
              size="small"
              label="Notes"
              InputLabelProps={{ shrink: true }}
              onChange={handleNotesChange}
              fullWidth={true}
              value={notes}
            />
          </Box>
          <Box>
            <DatePicker
              label="Requested Delivery Date"
              fullWidth={true}
              format={'MM-DD-YYYY'}
              value={requestedDeliveryDate}
              onChange={handleRequestedDeliveryDateChange}
              animateYearScrolling
            />
          </Box>
        </div>
        <div>
          <NewOrderTable
            firebase={props.firebase}
            db={props.db}
            orderUtilities={createOrderUtilities()}
            customerRef={customerRef}
            customerRefs={props.customerRefs}
            subdivisions={props.subdivisions}
            city={city}
            cities={props.cities} 
            materials={props.materials} 
            purposes={props.purposes} 
            pricing={props.pricing}
            drivers={props.drivers}
            rowColorMap={props.rowColorMap}
            lineItemsChanged={lineItemsChanged}
            lineItems={lineItems}
            tableRef={lineTableRef}
            isFuture={props.isFuture}
          />
        </div>  
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>Cancel</Button>
        <Button color="primary" onClick={onSubmitOrder} disabled={!canAddOrder}>Add Order</Button>
      </DialogActions>
    </Dialog>
  )
}
