import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import SubdivisionTable from '../subdivisions/SubdivisionTable'
import CustomersTable from '../customers/CustomersTable'
import PitsTable from '../pits/PitsTable'
import CitiesTable from '../cities/CitiesTable'
import DriversTable from '../drivers/DriversTable'
import UsersTable from '../users/UsersTable'
import AllOrders from '../orders/AllOrders'

MetaDataContainer.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  customerRefs: PropTypes.array.isRequired,
  pricingModels: PropTypes.array.isRequired,
  subdivisions: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  pricing: PropTypes.array.isRequired
}

export default function MetaDataContainer(props) {

  const items = [
    'Subdivisions',
    'Customers',
    'Pits',
    'Cities',
    'Drivers',
    'Archived Orders',
    'Users'
  ]
  
  const [value, setValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Tabs value={value} onChange={handleTabChange} >
          {items.map(item => (
            <Tab key={item} label={item}/>
          ))}
      </Tabs>
      {value === 0 &&
        <SubdivisionTable firebase={props.firebase} db={props.db} />
      }
      {value === 1 &&
        <CustomersTable customerRefs={props.customerRefs} firebase={props.firebase} db={props.db} pricingModels={props.pricingModels} subdivisions={props.subdivisions}/>
      }
      {value === 2 &&
        <PitsTable firebase={props.firebase} db={props.db} />
      }
      {value === 3 &&
        <CitiesTable firebase={props.firebase} db={props.db} />
      }
      {value === 4 &&
        <DriversTable firebase={props.firebase} db={props.db} />
      }
      {value === 5 &&
        <AllOrders firebase={props.firebase} db={props.db} 
            customerRefs={props.customerRefs} 
            materials={props.materials}
            purposes={props.purpose}
            cities={props.cities}
            subdivisions={props.subdivisions}
            pricing={props.pricing}
            drivers={props.drivers}
            rowColorMap={props.rowColorMap} />
      }
      {value === 6 &&
        <UsersTable firebase={props.firebase} db={props.db} />
      }
    </div>
  )

}