import React, {forwardRef, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import MaterialTable from 'material-table'
import { CsvBuilder } from "filefy";
import DialogForOrderReferenceOrders from './DialogForOrderReferenceOrders'
import { convertDateToDateString,
         convertFirestoreTimestampToDate } from '../common/FirestoreUtilities'
import { SvgIcon } from '@material-ui/core'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit'

import SvgEdit from '../icons/Edit';

import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ThumbUp from '@material-ui/icons/ThumbUp';
import OrdersTableActions from './OrdersTableActions';
import OrdersTableColumns from './OrdersTableColumns';
import ConvertOrderToReady from './ConvertOrderToReady'
import ConvertOrderToDispatch from './ConvertOrderToDispatch'
import ConvertOrderToDeliver from './ConvertOrderToDeliver'
import SplitOrder from './SplitOrder'
import DialogForPoOrders from './DialogForPoOrders'

OrdersTable.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  actions: PropTypes.array,
  isReadOnly: PropTypes.bool,
  customerRefs: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  subdivisions: PropTypes.array.isRequired,
  pricing: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  rowColorMap: PropTypes.object.isRequired,
  ordersWithRefs: PropTypes.array,
  orderUtilities: PropTypes.object,
  paging: PropTypes.bool,
  onSelectionChange: PropTypes.func
}

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => (<Edit {...props} ref={ref} />)),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ThumbUp: forwardRef((props, ref) => <ThumbUp {...props} ref={ref} />)
};

export default function OrdersTable(props) {

  const [convertOrderToReady, setConvertOrderToReady] = useState(false)
  const [convertOrderToDispatch, setConvertOrderToDispatch] = useState(false)
  const [convertOrderToDeliver, setConvertOrderToDeliver] = useState(false)
  const [showPo, setShowPo] = useState(false)
  const [showOrderReference, setShowOrderReference] = useState(false)
  const [splitOrder, setSplitOrder] = useState(false)
  const [currentRow, setCurrentRow] = useState(undefined)
  
  const actionsRef = useRef(undefined)
  const [columnsRef, setColumnsRef] = useState(undefined)
  const orderUtilitiesRef = useRef(undefined)

  orderUtilitiesRef.current = props.orderUtilities

  const editable = props.isReadOnly ? undefined : {
    onRowUpdate: async (newData, oldData) =>{
      if (props.onUpdate) {
        props.onUpdate(newData)
      }
      const data = { ...newData }
      delete data.ref  
      delete data.tableData
      orderUtilitiesRef.current.updateOrder(oldData.ref, data, false).catch(err => {
        alert(err)
      })
    },
  }

  useEffect(() => {

    const orderstablecolumns = new OrdersTableColumns(props.customerRefs, props.cities, props.subdivisions, props.materials, props.purposes, props.drivers, openShowPo, openShowOrderReference)
    setColumnsRef(orderstablecolumns.makeColumnList(props.columns))
  
  }, [props.columns,props.customerRefs, props.cities, props.subdivisions, props.materials, props.purposes, props.drivers,props.tabValue]
  )

  const myExportCsv = (columns, data) => {
    // let fileName = this.props.title || "data";
    const fileName = prompt('Please enter a filename without an extension', 'dwr')

    // if (this.props.exportFileName) {
    //   fileName =
    //     typeof this.props.exportFileName === "function"
    //       ? this.props.exportFileName()
    //       : this.props.exportFileName;
    // }

    const builder = new CsvBuilder(fileName + ".csv");
    const convertedColumns = columns.filter(column => {
      return column.title ? true : false
    })
    convertedColumns.push({ title: 'Ref (do not modify)', field: 'ref'})
    const convertedRows = []
    data.forEach(row => {
      const convertedRow = []
      convertedColumns.forEach(column => {
        let value = row[column.field]
        if (value instanceof Date) {
          value = convertDateToDateString(props.firestore, value)
        }
        convertedRow.push(value)
      })
      convertedRows.push(convertedRow)
    })
    builder
      // .setDelimeter(this.props.exportDelimiter)
      .setDelimeter(",")
      .setColumns(convertedColumns.map((columnDef) => columnDef.title))
      .addRows(convertedRows)
      .exportFile();
  }

  const processFutureAction = (rowData) => {
    if (!window.confirm('Are you sure you want to make this a FUTURE order')) {
      return
    }
    const data = { ...rowData }
    delete data.ref
    delete data.tableData
    orderUtilitiesRef.current.setOrderToFuture(rowData.ref, data)
  }

  const determineIfOldOrder = (order) => {
    // if it's already delivered, it's not considered old
    if (order.delivered) return false
    if (!order.orderDate) return false
    const millisecondsInDay = 86400000
    const orderDate = convertFirestoreTimestampToDate(props.firestore, order.orderDate)
    const orderDateTime = orderDate.getTime()
    const todayDate = new Date()
    const todayTime = todayDate.getTime()
    return (todayTime - orderDateTime) > (3 * 7 * millisecondsInDay)
  }

  const processReadyAction = (rowData) => {
    if (rowData.delivered || rowData.readyToInvoice) { 
      if (!window.confirm('Are you sure you want to make this a READY order')) {
        return
      }
      const data = { ...rowData }
      delete data.ref
      delete data.tableData
      orderUtilitiesRef.current.moveOrderBackToReadyAfterDelivery(rowData.ref, data)
      setCurrentRow(rowData)
      return
    }
    if (rowData.dispatched) { 
      if (!window.confirm('Are you sure you want to make this a READY order')) {
        return
      }  
      const data = { ...rowData }
      delete data.ref
      delete data.tableData
      orderUtilitiesRef.current.setDispatchedOrderToReady(rowData.ref, data)
      return
    }
    setCurrentRow(rowData)
    setConvertOrderToReady(true)
  }

  const finishedConvertingToReady = () => {
    setConvertOrderToReady(false)
  }

  const processDeliverAction = (rowData) => {
    setCurrentRow(rowData)
    setConvertOrderToDeliver(true)
  }

  const processSplitAction = (rowData) => {
    if (rowData.length) {
      setCurrentRow(rowData[0])
      setSplitOrder(true)  
    } else {
      setCurrentRow(rowData)
      setSplitOrder(true)  
    }
  }

  const processDispatchAction = (rowData) => {
    setCurrentRow(rowData)
    setConvertOrderToDispatch(true)
  }

  const openShowPo = (po, customer) => {
    setShowPo({po:po,customer:customer})
  }

  const closeShowPo = () => {
    setShowPo(undefined)
  }

  const openShowOrderReference = (orderReference) => {
    setShowOrderReference(orderReference)
  }

  const closeShowOrderReference = () => {
    setShowOrderReference(undefined)
  }

  if (props.actions) {
    const ordersTableActions = new OrdersTableActions(props.orderUtilities, 
      processReadyAction,
      processFutureAction,
      processDispatchAction,
      processDeliverAction,
      processSplitAction)
    actionsRef.current = ordersTableActions.makeActionList(props.actions)  
  }

  const finishedConvertingToDeliver = () => {
    setConvertOrderToDeliver(false)
  }

  const finishedSplittingOrder = () => {
    setSplitOrder(false)
  }

  const finishedConvertingToDispatch = () => {
    setConvertOrderToDispatch(false)
  }

  const determineBorder = (order) => {
    const oldOrder = determineIfOldOrder(order)
    let borderColor = 'rgb(255,255,255)'
    borderColor = oldOrder ? 'rgb(0,0,255)' : borderColor
    // priority overrides oldOrder
    borderColor = order.priority ? 'rgb(0,0,255)' : borderColor
    const borderStyle = order.priority || oldOrder ? 'solid' : 'none'
    const rowStyle = {
        borderColor: borderColor,
        borderStyle: borderStyle
    }
    return rowStyle
  }


  if (convertOrderToReady === true) {
    return (<ConvertOrderToReady 
      row={currentRow}
      orderUtilities={orderUtilitiesRef.current}
      customerRefs={props.customerRefs}
      onClose={finishedConvertingToReady}
      materialColor={props.rowColorMap.get(currentRow.purpose.toUpperCase())}
    />)
  }  

  if (showPo) {
    return (<DialogForPoOrders 
      firebase={props.firebase} 
      db={props.db} 
      customerRefs={props.customerRefs} 
      materials={props.materials}
      purposes={props.purposes}
      cities={props.cities}
      subdivisions={props.subdivisions}
      pricing={props.pricing}
      drivers={props.drivers}
      rowColorMap={props.rowColorMap}
      po={showPo.po}
      customer={showPo.customer}
      onClose={closeShowPo}
    />)
  }  

  if (showOrderReference) {
    return (<DialogForOrderReferenceOrders 
      firebase={props.firebase} 
      db={props.db} 
      customerRefs={props.customerRefs} 
      materials={props.materials}
      purposes={props.purposes}
      cities={props.cities}
      subdivisions={props.subdivisions}
      pricing={props.pricing}
      drivers={props.drivers}
      rowColorMap={props.rowColorMap}
      orderReference={showOrderReference}
      onClose={closeShowOrderReference}
    />)
  }  

  if (convertOrderToDispatch === true) {
    return (<ConvertOrderToDispatch 
      row = {currentRow}
      orderUtilities = {orderUtilitiesRef.current}
      onClose={finishedConvertingToDispatch}
      drivers={props.drivers}
    />)
  }  

  if (convertOrderToDeliver === true) {
    if (currentRow.readyToInvoice) {
      if (!window.confirm('Are you sure you want to make this a DELIVERED order')) {
        setConvertOrderToDeliver(false)
        return
      }  
      const data = {...currentRow}
      delete data.ref
      delete data.tableData
      orderUtilitiesRef.current.removeReadyToInvoice(currentRow.ref, data)
      setConvertOrderToDeliver(false)
    }
    return (<ConvertOrderToDeliver 
      row = {currentRow}
      orderUtilities = {orderUtilitiesRef.current}
      onClose={finishedConvertingToDeliver}
      driverRefs={props.drivers}
    />)
  }  

  if (splitOrder === true) {
    return (<SplitOrder
      row = {currentRow}
      orderUtilities = {orderUtilitiesRef.current}
      onClose={finishedSplittingOrder}
    />)
  }  

  const tempPaging = props.paging === undefined ? false: props.paging

  return (
    <MaterialTable
      icons={tableIcons}
      title={props.title}
      columns={columnsRef}
      data={props.ordersWithRefs}
      actions={actionsRef.current}
      options={{
        exportCsv: myExportCsv,
        exportButton: true,
        selection: props.selection,
        padding: 'dense',
        paging: tempPaging,
        emptyRowsWhenPaging: false,
        pageSize: props.pageSize? props.pageSize : 20,
        pageSizeOptions: [5,10,20,50,100,500,1000],
        filtering: true,
        search: true,
        sorting: true,
        thirdSortClick: false,
        rowStyle: rowData => ({
          backgroundColor: props.rowColorMap.get(rowData.purpose.toUpperCase()),
          borderColor: determineBorder(rowData).borderColor,
          borderStyle: determineBorder(rowData).borderStyle,
          color: rowData.heavy? 'rgb(255,0,0)' : 'rgb(0,0,0)',
        }) 
      }}
      onSelectionChange={props.onSelectionChange}
      editable={editable}
    />
  )
}