import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const configProd = {
  apiKey: 'AIzaSyClMR0jcjycWITWfVX4LzPsV_1pdgxVosI',
  authDomain: "easeurload.firebaseapp.com",
  projectId: 'easeurload'
}

const configDev = {
  apiKey: "AIzaSyC8VK55a5G55AmiyE4rWprpP-vghnGXQBY",
  authDomain: "easeurloaddev.firebaseapp.com",
  projectId: 'easeurloaddev'
}

var config
var environment = "Unknown"

if (window.location.hostname === "www.easeurload.com" ) {
  config = configProd
  environment = ""
}
if (window.location.hostname === "easeurloaddev.web.app" ) {
  config = configDev
  environment = "Development"
}
if (window.location.hostname === "localhost") {
  config =configProd
  environment = "Local"
}

firebase.initializeApp(config)
let db = firebase.firestore()
let firebaseauth = firebase.auth()
if (window.location.hostname === "localhost") {
  db.useEmulator("localhost", 8080)
  firebaseauth.useEmulator("http://localhost:9099")
}

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#484848',
      main: '#212121',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffffff',
      main: '#f9f9f9',
      dark: '#c6c6c6',
      contrastText: '#000',
    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 12,
  },
  overrides: {
    MuiToolbar: {
      regular: {
        '@media (min-width: 600px)': {
          'min-height': "50px",
        },
      }
    },
    MuiSvgIcon: {
      root: {
        fill: 'black'
      }
    }
  },
});

ReactDOM.render(
  <React.Fragment>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <App firebase={firebase} db={db} firebaseauth={firebaseauth} env={environment}/>
          </MuiPickersUtilsProvider>
        </CssBaseline>
      </ThemeProvider>
    </BrowserRouter>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
